<template>
  <router-view />
</template>

<script>
import categoriesModule from '../../store/categories'

export default {
  beforeCreate () {
    this.$store.registerModule('categories', categoriesModule)
  },
  destroyed () {
    this.$store.unregisterModule('categories')
  }
}
</script>
