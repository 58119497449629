import {
  CATEGORIES_BATCH_DELETE_REQUEST,
  CATEGORIES_BATCH_DELETE_SUCCESS,
  CATEGORIES_BATCH_DELETE_FAILURE,
  CATEGORIES_FETCH_MANY_REQUEST,
  CATEGORIES_FETCH_MANY_SUCCESS,
  CATEGORIES_FETCH_MANY_FAILURE,
  CATEGORIES_FETCH_ALL_REQUEST,
  CATEGORIES_FETCH_ALL_SUCCESS,
  CATEGORIES_FETCH_ALL_FAILURE,
  CATEGORIES_FETCH_REQUEST,
  CATEGORIES_FETCH_SUCCESS,
  CATEGORIES_FETCH_FAILURE,
  CATEGORIES_SAVE_REQUEST,
  CATEGORIES_SAVE_SUCCESS,
  CATEGORIES_SAVE_FAILURE,

  EMPTY_CATEGORIES,
  CLEAR_ERRORS
} from './types'

export default {
  deleteMany ({ commit }, { ids = [] }) {
    commit(CATEGORIES_BATCH_DELETE_REQUEST)
    return window.axios.post('/categories', { _method: 'DELETE', ids }).then(response => {
      commit(CATEGORIES_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CATEGORIES_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchMany ({ commit }, { query = '', page = 1 }) {
    commit(CATEGORIES_FETCH_MANY_REQUEST)
      return window.axios
          .get('/categories', { params: { query, page } })
          .then(response => {
              commit(CATEGORIES_FETCH_MANY_SUCCESS, response)
              return response;
          })
          .catch(error => {
              commit(CATEGORIES_FETCH_MANY_FAILURE, error)
              throw error;
          });
  },
  fetchAll ({ commit }) {
    commit(CATEGORIES_FETCH_ALL_REQUEST)
      return window.axios
          .get('/categories/all')
          .then(response => {
              commit(CATEGORIES_FETCH_ALL_SUCCESS, response)
              return response;
          })
          .catch(error => {
              commit(CATEGORIES_FETCH_ALL_FAILURE, error)
              throw error;
          });
  },
  fetch ({ commit }, id) {
    commit(CATEGORIES_FETCH_REQUEST)
    return window.axios.get(`/categories/${id}`).then(response => {
      commit(CATEGORIES_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CATEGORIES_FETCH_FAILURE, error)
      throw error
    })
  },
  save ({ commit }, user) {
    const path = user.id ? `/categories/${user.id}` : '/categories'
    const method = user.id ? 'put' : 'post'

    commit(CATEGORIES_SAVE_REQUEST)
    return window.axios[method](path, user).then(response => {
      commit(CATEGORIES_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CATEGORIES_SAVE_FAILURE, { validationErrors: error.response.data })
      } else {
        commit(CATEGORIES_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  empty({ commit }) {
    commit(EMPTY_CATEGORIES);
  },
  clearErrors({ commit }) {
    commit(CLEAR_ERRORS)
  }
}
