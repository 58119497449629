<template>
	<b-container>
		<b-row class="mb-3">
			<b-col cols="6">
				<h1 class="font-weight-bold text-primary">View Category</h1>
			</b-col>
			<b-col cols="6">
				<div class="d-none d-md-flex flex-row justify-content-end pt-2">
					<b-button class="mr-1 mb-0" size="sm" variant="primary" v-b-popover.hover.bottom="'Edit this category.'" :to="{ name: 'dash.categories.update', params: { id: category.id } }"><b-icon icon="pen" /> Edit Category</b-button>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="isLoading">
			<b-col cols="12">
				<p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
			</b-col>
		</b-row>
		<b-row v-if="!isLoading">
			<b-col cols="12">
				<b-row>
					<b-col cols="12">
						<b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
							<b-form-input disabled :state="validationState(errors.errors, 'name')" v-model="category.name" />
						</b-form-group>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
        <!-- Category Products -->
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col class="d-flex flex-row" cols="12" md="10" @click.prevent="toggleCollapse('product_list')" role="button">
                            <b-icon class="align-self-center cursor-pointer text-muted" font-scale="1.5" :icon="collapsed['product_list'] ? 'chevron-right' : 'chevron-down'"></b-icon>
                            <div class="d-flex flex-column flex-fill ml-4">
                                <p class="h4 font-weight-bold mb-0">Product List</p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4" v-if="!collapsed['product_list']">
                        <b-col cols="12" v-if="!category.products.length">
                            <b-col class="text-center" cols="12">
                                <b-card class="py-3 text-center">
                                    <p class="mb-4"><fa-icon class="display-1 text-dark" icon="times"></fa-icon></p>
                                    <p class="font-weight-normal h5 mb-0 text-dark">There are no products under this category.</p>
                                </b-card>
                            </b-col>
                        </b-col>
                        <b-col cols="12" v-if="category.products.length">
                            <b-table-simple striped hover class="overflow-auto">
                                <b-thead>
                                    <b-tr>
                                        <b-th>Name</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(product, productId) in category.products" :key="productId">
                                        <b-td>{{ product.name }}</b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

	</b-container>
</template>

<script>
import validation from "../../mixins/validation"
import { mapActions, mapGetters } from "vuex"

export default {
	mixins: [validation],

	created() {
		if (this.$route.params.id) {
			this.fetch(this.$route.params.id)
		}
	},
	computed: {
		...mapGetters("categories", ["errors", "isLoading", "isSaving", "single"]),
	},
	data() {
		return {
            collapsed: {
                product_list: !this.$route.params.id
            },
            category: {
                name: null,
                products: [],
            },
		}
	},
	methods: {
		...mapActions("categories", ["fetch", "save"]),

		toggleCollapse(name) {
			this.collapsed[name] = !this.collapsed[name]
		},
	},
	watch: {
		single() {
			this.category = this.single
		},
	},
}
</script>

<style lang="scss">
.note-header {
	font-size: 1.5rem;
}
.note {
	font-size: 1.5rem;
	margin: 1rem;
}
.note-created {
	font-size: 0.9rem;
	margin: 0;
}
</style>
