import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const namespaced = true

export default {
  namespaced,
  state: () => ({
    validationErrors: {},
    deleting: false,
    loading: false,
    saving: false,
    single: {},
    data: [],
    page: {
      current: 1,
      total: 0,
      from: 0,
      last: 1,
      per: 15,
      to: 0
    }
  }),
  mutations,
  getters,
  actions
}
