export const CATEGORIES_BATCH_DELETE_REQUEST = 'CATEGORIES_BATCH_DELETE_REQUEST'
export const CATEGORIES_BATCH_DELETE_SUCCESS = 'CATEGORIES_BATCH_DELETE_SUCCESS'
export const CATEGORIES_BATCH_DELETE_FAILURE = 'CATEGORIES_BATCH_DELETE_FAILURE'

export const CATEGORIES_FETCH_MANY_REQUEST = 'CATEGORIES_FETCH_MANY_REQUEST'
export const CATEGORIES_FETCH_MANY_SUCCESS = 'CATEGORIES_FETCH_MANY_SUCCESS'
export const CATEGORIES_FETCH_MANY_FAILURE = 'CATEGORIES_FETCH_MANY_FAILURE'

export const CATEGORIES_FETCH_ALL_REQUEST = 'CATEGORIES_FETCH_ALL_REQUEST'
export const CATEGORIES_FETCH_ALL_SUCCESS = 'CATEGORIES_FETCH_ALL_SUCCESS'
export const CATEGORIES_FETCH_ALL_FAILURE = 'CATEGORIES_FETCH_ALL_FAILURE'

export const CATEGORIES_FETCH_REQUEST = 'CATEGORIES_FETCH_REQUEST'
export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS'
export const CATEGORIES_FETCH_FAILURE = 'CATEGORIES_FETCH_FAILURE'

export const CATEGORIES_SAVE_REQUEST = 'CATEGORIES_SAVE_REQUEST'
export const CATEGORIES_SAVE_SUCCESS = 'CATEGORIES_SAVE_SUCCESS'
export const CATEGORIES_SAVE_FAILURE = 'CATEGORIES_SAVE_FAILURE'

export const EMPTY_CATEGORIES = 'EMPTY_CATEGORIES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
