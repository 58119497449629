<template>
  <div>
    <b-row class="mb-2">
      <b-col class="mb-2 mb-md-0" cols="12" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">{{ title }}</h1>
        <p class="mb-2">
          <small>
            <b-icon v-if="isLoading" icon="question-circle"/>
            {{ isLoading ? "" : data.length }} {{ data.length === 1 ? resource : resourcePlural }}
            found
          </small>
        </p>
      </b-col>
      <b-col v-if="hasSearch" class="d-md-none mb-2" cols="12">
        <b-input class="mr-2" placeholder="Search..." @update="onSearch"></b-input>
      </b-col>
      <b-col v-if="hasSearch" class="d-flex flex-row align-items-center justify-content-start justify-content-md-end"
             cols="12"
             md="8">
        <b-input class="mr-2 d-none d-md-block" placeholder="Search..." @update="onSearch"></b-input>
        <b-button v-if="canCreate && !selected.length" :to="{ name: createTo }" class="mr-2 text-nowrap"
                  variant="secondary">Create {{ createText ? createText : resourceNameCasing("camel", resource) }}
        </b-button>
        <b-button v-if="selected.length" :disabled="!data.length" class="mr-2 text-nowrap" variant="dark"
                  @click.prevent="selectAll"><span
          v-if="selected.length < data.length || !data.length">Select All</span>
          <span v-if="selected.length === data.length && data.length">Deselect All</span>
        </b-button>
        <b-button v-if="canDelete && canSelectMany && selected.length" v-b-modal.delete-many-resources
                  class="mr-2 text-nowrap" variant="danger">Delete
          {{ resourceNameCasing("camel", resourcePluralIf(selected)) }}
        </b-button>
        <b-dropdown v-if="hasButtons" class="ml-auto ml-md-0" no-caret right variant="dark">
          <template #button-content>
            <b-icon font-scale="1.2" icon="three-dots-vertical"></b-icon>
          </template>
          <b-dd-item v-if="canCreate && selected.length" :to="{ name: createTo }" variant="secondary">Create
            {{ resourceNameCasing("camel", resource) }}
          </b-dd-item>
          <b-dd-divider v-if="selected.length"/>
          <b-dd-item-button v-if="canSelectMany && !selected.length" :disabled="!data.length"
                            @click.prevent="selectAll"><span v-if="selected.length < data.length || !data.length">Select All</span><span
            v-if="selected.length === data.length && data.length">Deselect All</span>
          </b-dd-item-button>
          <slot name="buttons"></slot>
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row v-if="$slots.utilityContainer">
      <b-col cols="12">
        <slot name="utilityContainer"></slot>
      </b-col>
    </b-row>
    <b-row v-if="hasFilters" class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col
              class="d-flex flex-row"
              cols="12"
              role="button"
              @click.prevent="toggleCollapse('filters')"
            >
              <b-icon
                :icon="collapsed['filters'] ? 'chevron-right' : 'chevron-down'"
                class="align-self-center cursor-pointer text-muted"
                font-scale="1.5"
              ></b-icon>
              <div class="d-flex flex-column flex-fill ml-4">
                <p class="h4 font-weight-bold mb-0">Filters</p>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!collapsed['filters']" class="mt-4">
            <b-col cols="12">
              <slot name="filters"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="isLoading" class="mt-5 mb-2">
      <b-col class="text-center" cols="12">
        <b-spinner variant="secondary"></b-spinner>
      </b-col>
    </b-row>
    <b-row v-if="!data.length && !isLoading && !hideList" class="mb-2">
      <b-col class="text-center" cols="12">
        <b-card class="py-5 text-center">
          <p class="mb-4">
            <b-icon
              class="display-1 text-dark"
              icon="cloud-arrow-down"
            ></b-icon>
          </p>
          <p class="font-weight-normal h5 mb-0 text-dark">
            Could not find any {{ resourcePlural }} that match the given
            criteria.
          </p>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="data && !isLoading && !hideList" class="mb-2">
      <b-col cols="12">
        <b-list-group>
          <b-list-group-item v-for="item in data" :key="item.id">
            <div class="d-flex flex-row justify-items-start">
              <div v-if="canSelectMany" class="align-self-center mr-2">
                <b-checkbox v-model="selected" :value="item.id"></b-checkbox>
              </div>
              <slot :item="item" name="listItem"/>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row
      v-if="data && !isLoading && pagination.per < pagination.total"
      class="mb-2"
    >
      <b-col cols="12">
        <b-pagination
          v-model="pagination.current"
          :per-page="pagination.per"
          :total-rows="pagination.total"
          @change="onPaginate"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-modal
      id="delete-many-resources"
      :cancel-disabled="isDeleting"
      :ok-disabled="isDeleting"
      :title="
        `Delete ${this.selected.length} ${resourcePluralIf(this.selected)}?`
      "
      cancel-title="Cancel"
      cancel-variant="dark"
      centered
      ok-title="Delete"
      ok-variant="danger"
      @ok.prevent="onDeleteModalOk"
    >
      <p class="text-center">
        <b-spinner v-if="isDeleting" class="my-2" variant="danger"/>
      </p>
      <p v-if="!isDeleting && !softDeletes">
        This action is destructive, which means they can not be restored.
      </p>
      <p v-if="!isDeleting && softDeletes">
        They will be marked as "trashed" and they will be deleted permanently
        after thirty days.
      </p>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      selected: [],
      collapsed: {
        filters: true
      }
    };
  },
  props: {
    canCreate: {
      default: true,
      type: Boolean
    },
    canDelete: {
      default: false,
      type: Boolean
    },
    canSelectMany: {
      default: true,
      type: Boolean
    },
    createTo: {
      default: "",
      type: String
    },
    data: {
      required: true,
      type: Array
    },
    hasFilters: {
      default: false,
      type: Boolean
    },
    hasButtons: {
      default: true,
      type: Boolean
    },
    isDeleting: {
      default: false,
      type: Boolean
    },
    isLoading: {
      default: false,
      type: Boolean
    },
    pagination: {
      required: true,
      type: Object
    },
    resource: {
      required: true,
      type: String
    },
    resourcePlural: {
      default() {
        return `${this.resource}s`;
      },
      type: String
    },
    softDeletes: {
      default: false,
      type: Boolean
    },
    hasSearch: {
      default: true,
      type: Boolean
    },
    searchQuery: {
      default: "",
      type: String
    },
    hideList: {
      default: false,
      type: Boolean
    },
    title: {
      required: true,
      type: String
    },
    createText: {
      required: false,
      default: null
    }
  },
  methods: {
    onDeleteModalOk(bvModalEvt) {
      this.$emit("delete-many", {
        ids: this.selected,
        modalId: bvModalEvt.target.id
      });
    },
    onPaginate(pageNo) {
      this.$emit("paginate", pageNo);
    },
    onSearch(query) {
      this.onPaginate(1);
      this.$emit("search", query);
    },
    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },
    resourceNameCasing(casing, resourceName) {
      if (!resourceName) {
        return "";
      }
      switch (casing) {
        case "camel":
          return resourceName.charAt(0).toUpperCase() + resourceName.slice(1);
        case "upper":
          return resourceName.toUpperCase();
        case "lower":
          return resourceName.toLowerCase();
      }
    },
    resourcePluralIf(arr) {
      return arr.length === 1 ? this.resource : this.resourcePlural;
    },
    selectAll() {
      if (this.selected.length === this.data.length) {
        this.selected = [];
      } else {
        this.selected = this.data.map(d => d.id);
      }
    }
  },
  watch: {
    data() {
      this.selected = [];
    }
  }
};
</script>
