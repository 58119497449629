<template>
	<b-container>
		<b-form @submit.prevent="submit">
			<b-row class="mb-3">
				<b-col cols="12">
					<h1 class="font-weight-bold text-primary">{{ $route.params.id ? "Update" : "Create" }} Category</h1>
				</b-col>
			</b-row>
			<b-row v-if="isLoading">
				<b-col cols="12">
					<p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
				</b-col>
			</b-row>
			<b-row v-if="!isLoading">
				<b-col cols="12">
					<b-row>
						<b-col cols="12">
							<b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors.errors, 'name')">
								<b-form-input :disabled="isSaving" :state="validationState(errors.errors, 'name')" v-model="category.name" />
							</b-form-group>
						</b-col>
					</b-row>


					<b-row class="mb-4">
						<b-col class="d-flex flex-row" cols="12" md="4" offset-md="8">
							<b-button block class="align-self-end mb-3" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-form>
	</b-container>
</template>

<script>
import validation from "../../mixins/validation"
import { mapActions, mapGetters } from "vuex"

export default {
	mixins: [validation],

	created() {
		if (this.$route.params.id) {
			this.fetch(this.$route.params.id)
		}
	},
	computed: {
		...mapGetters("categories", ["errors", "isLoading", "isSaving", "single"]),
	},
	data() {
		return {
			category: {
                name: null
			},
		}
	},
	methods: {
		...mapActions("categories", ["fetch", "save"]),

		toggleCollapse(name) {
			this.collapsed[name] = !this.collapsed[name]
		},

		submit() {
			let data = {
				id: this.$route.params.id,
                name: this.category.name
			}

			this.save(data).then((response) => {
				if ([200, 201].includes(response.status))
					this.$router.push({
						name: "dash.categories.list",
						params: {
							notifications: [
								{
									variant: "success",
									title: `Category #${response.data.id}`,
									subtitle: `${response.data.name}`,
									text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} category.`,
									timeout: 5000,
								},
							],
						},
					})
			})
		},
	},
	watch: {
		single() {
			this.category = this.single
		},
	},
}
</script>

<style lang="scss">
.note-header {
	font-size: 1.5rem;
}
.note {
	font-size: 1.5rem;
	margin: 1rem;
}
.note-created {
	font-size: 0.9rem;
	margin: 0;
}
</style>
